var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-auto"}),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$router.push({ name: 'sales-create' })}}},[_c('i',{staticClass:"bi bi-plus-square"}),_vm._v(" Créer ")]),_c('button',{staticClass:"btn btn-info",on:{"click":function($event){return _vm.refresh()}}},[_c('i',{staticClass:"bi bi-arrow-clockwise"}),_vm._v(" Actualiser")])])]),_c('table',{staticClass:"table table-striped table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.sales.filter((p) => p.remark != '')),function(sale,index){return _c('tr',{key:index++},[_c('td',[_vm._v(" "+_vm._s(index)+" ")]),_c('td',{staticClass:"p-0 m-0"},[_c('button',{staticClass:"btn btn-link ms-2",on:{"click":function($event){return _vm.$router.push({
                name: 'profile-sale',
                params: {
                  reference: sale.reference,
                },
              })}}},[_vm._v(" "+_vm._s(sale.reference)+" ")])]),_c('td',[(sale.customer)?_c('span',[_vm._v(" "+_vm._s(sale.customer.fullName)+" ")]):_vm._e()]),_c('td',[_vm._v(_vm._s(sale.date))]),_c('td',{staticClass:"text-warning"},[_vm._v(_vm._s(sale.totalPriceTTC))]),_c('td',[(sale.user)?_c('span',[_vm._v(" "+_vm._s(sale.user.name)+" ")]):_vm._e()]),_c('td',{staticClass:"text-warning"},[_vm._v(_vm._s(sale.remark))]),_c('td',{staticClass:"p-0 m-0"},[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.$router.push({
                name: 'sales-details',
                params: { reference: sale.reference },
              })}}},[_c('i',{staticClass:"bi bi-info-square fs-5"})])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}}),_c('th',{attrs:{"scope":"col"}},[_vm._v("Référence Nº")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Nom")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Date")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Montant Total")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Remarque")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Créer par")])])])
}]

export { render, staticRenderFns }