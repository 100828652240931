<template>
  <div>
    <div class="row justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'sales-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer
        </button>
        <button @click="refresh()" class="btn btn-info">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser</button>
      </div>
    </div>

    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence Nº</th>
          <th scope="col">Nom</th>
          <th scope="col">Date</th>
          <th scope="col">Montant Total</th>
          <th scope="col">Remarque</th>
          <th scope="col">Créer par</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(sale, index) in sales.filter((p) => p.remark != '')"
          :key="index++"
        >
          <td>
            {{ index }}
          </td>
          <td class="p-0 m-0">
            <button
              class="btn btn-link ms-2"
              @click="
                $router.push({
                  name: 'profile-sale',
                  params: {
                    reference: sale.reference,
                  },
                })
              "
            >
              {{ sale.reference }}
            </button>
          </td>
          <td>
            <span v-if="sale.customer">
              {{ sale.customer.fullName }}
            </span>
          </td>
          <td>{{ sale.date }}</td>
          <td class="text-warning">{{ sale.totalPriceTTC }}</td>

          <td>
            <span v-if="sale.user">
              {{ sale.user.name }}
            </span>
          </td>
          <td class="text-warning">{{ sale.remark }}</td>
          <td class="p-0 m-0">
            <button
              @click="
                $router.push({
                  name: 'sales-details',
                  params: { reference: sale.reference },
                })
              "
              class="btn"
            >
              <i class="bi bi-info-square fs-5"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
    };
  },
  computed: {
    ...mapGetters("sale", {
      sales: "getAll",
    }),
  },
  mounted() {
    this.$store.dispatch("sale/getAll");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("sale/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("sale/getAll");
    },

    async listSales(checkbox) {
      if (checkbox == true) {
        console.log(checkbox);
        return await this.$store.commit("sale/getTodays");
      } else {
        return await this.$store.dispatch("sale/getSales");
      }
    },
    async sort(value) {
      return await this.$store.commit("sale/sort", value);
    },
  },
};
</script>
